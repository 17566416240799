@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Syne";
    src: local("Syne"),
        url("./fonts/Syne/syne-bold-webfont.woff2") format("woff2");
    font-weight: normal;
}

.font-syne {
    -webkit-text-fill-color: #fff; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
}
  
.text-4xl {
    @apply font-syne;
}


#treasury-health {
    -webkit-appearance: meter;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    height: 50px;
    border: 1px solid #ccc;
}

#treasury-health::-webkit-meter-optimum-value {
    box-shadow: 0 5px 5px -5px #aaa inset;
    background-image: linear-gradient(
      90deg, 
      #8bcf69 0%, 
      #8bcf69 100%
    );
    background-size: 100% 100%;
}

#treasury-health::-webkit-meter-suboptimum-value {
    box-shadow: 0 5px 5px -5px #aaa inset;
    background-image: linear-gradient(
      90deg, 
      #e6d450 0%, 
      #e6d450 100%
    );
    background-size: 100% 100%;
}

#treasury-health::-webkit-meter-even-less-good-value {
    box-shadow: 0 5px 5px -5px #aaa inset;
    background-image: linear-gradient(
      90deg, 
      #f28f68 0%, 
      #f28f68 100%
    );
    background-size: 100% 100%;
}



#treasury-health::-webkit-meter-bar {
    background-color: #101010 !important;
}




body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.ql-toolbar {
    @apply rounded-t-md;
    background-color: #aaa;
}

.ql-toolbar button {
    border: 0;
}

.ql-container {
    @apply bg-black p-4 shadow-sm block w-full border border-gray-500 text-gray-100 rounded-b-md focus:ring-2  focus:outline-none focus:ring-maroon-flush focus:border-maroon-flush-200;
}

.ql-editor {
    @apply prose prose-invert;
}

.mantine-Tooltip-body {
    padding: 1rem;
}

.btn {
    @apply border border-maroon-flush mt-5 inline-flex items-center justify-center p-2 px-6 rounded-md text-white hover:bg-maroon-flush focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white;
}

.btn-fill {
    @apply bg-maroon-flush hover:bg-maroon-flush-600;
}

.btn-gray {
    @apply border border-gray-400 mt-5 inline-flex items-center justify-center p-2 px-6 rounded-md text-white hover:text-gray-900 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white;
}

.btn:disabled,
.btn-fill:disabled,
.btn-gray:disabled,
button:disabled {
    @apply disabled:opacity-50 pointer-events-none;
}

.input {
    @apply bg-[#111111] p-4 shadow-sm block w-full bg-gray-800 text-gray-100 rounded-md focus:ring-2  focus:outline-none focus:ring-white;
}

@-moz-document url-prefix() {
    .sol {
        @apply -mt-[0.4em];
    }
}

.tos-pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
}
